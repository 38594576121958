<template>
  <v-app>
    <v-snackbar
      :timeout="4000"
      :value="true"
      absolute
      top
      color="success"
      right
      text
      v-if="appear"
    >
      {{msg}}
    </v-snackbar>
    <div class="holder">
      <div style="width: 400px;float: left">
        <v-card class="card-prop">
          <img class="img-card" src="@/assets/icons/me.jpg" alt="school logo">
          <template>
            <VueFileAgent
              ref="vueFileAgent"
              :theme="'list'"
              :multiple="true"
              :deletable="true"
              :meta="true"
              :accept="'image/*'"
              :maxSize="'2MB'"
              :maxFiles="1"
              :helpText="'Choose image'"
              :errorText="{
                type: 'Invalid file type. Only images Allowed',
                size: 'Files should not exceed 2MB in size',
              }"
              @select="filesSelected($event)"
              @beforedelete="onBeforeDelete($event)"
              @delete="fileDeleted($event)"
              v-model="fileRecords"
            ></VueFileAgent>
            <button :disabled="!fileRecordsForUpload.length" @click="uploadFiles()">
              Upload {{ fileRecordsForUpload.length }} files
            </button>
          </template>
        </v-card>
        <v-card class="card-prop">
          <div style="margin: 10px 0 0 10px;">
            <span style="padding-bottom: 15px;"><strong>Active Intake:&nbsp;</strong></span><span>{{intakes.text}}</span><br>
            <span style="padding-bottom: 15px;"><strong>Status:&nbsp;</strong></span><span>{{intakes.status}}</span><br>
            <span style="padding-bottom: 15px;"><strong>Students:&nbsp;</strong></span><span>{{intakes.students}}</span><br>
          </div>
        </v-card>
      </div>
      <div class="tabs">
        <v-card class="card-prop" style="margin-right: 10px;">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="blue"
            grow
          >
            <v-tab>
              <span v-on:click="clicked()">School Info</span>
            </v-tab>
            <v-tab
            >
              Student card option
            </v-tab>
            <v-tab
            >
              Change Active semester
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card
                color="basil"
                flat
                v-for="school in schoolInfo" :key="school.id"
              >
                <div class="line">
                  <v-card-text><strong>School name:</strong>&nbsp;{{ school.name }}</v-card-text>
                </div>
                <div class="line">
                  <v-card-text><strong>Acronym:</strong>&nbsp;&nbsp;{{ school.acronym }}</v-card-text>
                </div>
                <div class="line">
                  <v-card-text><strong>Email:</strong>&nbsp;{{ school.email }}</v-card-text>
                </div>
                <div class="line">
                  <v-card-text><strong>Phone:</strong>&nbsp;{{ school.phone }}</v-card-text>
                </div>
                <div class="line">
                  <v-card-text><strong>Principal:</strong>&nbsp;{{ school.headmaster }}</v-card-text>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card
                color="basil"
                flat
                v-for="school in schoolInfo" :key="school.id"
              >
                <p>Tab 2</p>
              </v-card>
            </v-tab-item>
            <v-tab-item style="width: 50%"
              v-for="school in schoolInfo" :key="school.id"
            >
              <v-container style="margin-left: 40px;">
<!--                <v-btn outlined style="margin-left: 400px; text-transform: none" @click="on = true"><i class="fa fa-plus" aria-hidden="true" style="color: #0a43ae;"></i>Academic year</v-btn>-->
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <span>School name</span><span style="float: right;"></span>
                    <v-text-field
                      name="Title"
                      outlined
                      dense
                      v-model="school.name"
                      readonly
                    ></v-text-field>
                    <span>Academic year</span><span style="float: right; cursor: pointer; color: #0e4ac4;" @click="on = true">Create new</span>
                    <v-select
                      name="Academic year"
                      v-model="year"
                      :items="academics"
                      outlined
                      dense
                      placeholder="Choose academic year"
                      v-validate="'required'"
                    ></v-select>
                   <span style="color: red;" v-show="errors.has('Academic year')" v-if="valid">{{ errors.first('Academic year') }}</span><br>
                    <span>Semester</span>
                    <v-select
                      name="Semester"
                      v-validate="'required'"
                      :items="semesters"
                      outlined
                      dense
                      v-model="semester"
                      placeholder="Choose semester"
                    ></v-select>
                    <span style="color: red;"
                      v-show="errors.has('Semester')" v-if="valid">{{ errors.first('Semester') }}</span><br>
                    <span>Password</span>
                    <v-text-field
                      name="Password"
                      v-model="password"
                      type="password"
                      outlined
                      dense
                      v-validate="'required'"
                      placeholder="Enter password to confirm"
                      @keyup="displayMsg"
                    ></v-text-field>
                    <span style="color: red;"
                          v-show="errors.has('Password')" v-if="valid">{{ errors.first('Password') }}</span>
                    <span style="color: red; white-space: nowrap;" v-if="caution"><i class="fa fa-exclamation-triangle"></i>&nbsp;Be aware that this will change active semester and its related data</span><br>
                    <v-btn color="blue" block @click="validate" style="color: white; text-transform: none;">Change</v-btn>
                    <center><CircleBars v-if="loading"/></center>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <v-dialog
          v-model="on"
          max-width="500px"
          :persistent="true"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Create new Academic year</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <span>Title</span>
                    <v-text-field
                      v-model="title"
                      name="Title"
                      v-validate="'required'"
                      outlined
                      dense
                      placeholder="2020-2021"
                      @keyup="disableBtn"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="on = false"
                outlined
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="create_academic_year"
                outlined
                :disabled="isDisabled"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <vue-confirm-dialog></vue-confirm-dialog>
    </div>
  </v-app>
</template>

<script>
import uploader from './Uploader'
import Swal from 'sweetalert2'
import CircleBars from '../statistics/progress-bars/Widgets/CircleBars'

export default {

  name: 'Settings',
  components: {
    uploader,
    CircleBars,
  },
  data () {
    return {
      tab: null,
      items: [
        'Basic School Info', 'Student card options', 'Grade setting',
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      logger: [],
      header: '',
      schoolInfo: [],
      intakes: [],
      fileRecords: [],
      uploadUrl: 'https://www.mocky.io/v2/5d4fb20b3000005c111099e3',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [],
      year: '',
      semesters: [
        { text: 'Semester one', value: 1 },
        { text: 'Semester two', value: 2 },
      ],
      semester: null,
      password: '',
      on: false,
      title: '',
      academics: [],
      appear: false,
      msg: '',
      valid: true,
      loading: false,
      caution: false,
      isDisabled: true,
    }
  },
  created () {
    this.fetchSchoolInfo()
    this.fetchIntakes()
    this.fetchAcademic()
  },
  methods: {
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.change_semester()
        } else {
          // form have errors
        }
      })
    },
    displayMsg () {
      if (this.password === '') {
        this.caution = false
      } else {
        this.caution = true
      }
    },
    disableBtn () {
      if (this.title === '') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
    info () {
      this.fetchSchoolInfo()
    },
    async change_semester () {
      this.$confirm(
        {
          message: 'Are you sure ?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: async confirm => {
            if (confirm) {
              // ... do something
              this.loading = true
              this.msg = 'Active semester changed'
              if (localStorage.getItem('logged_user')) {
                this.logger = JSON.parse(localStorage.getItem('logged_user'))
              }
              this.header = this.logger.accessToken
              const data = {
                semester: this.semester,
                year: this.year,
                password: this.password,
              }
              try {
                const res = await this.axios({
                  url: 'manipulate_active_semester',
                  method: 'post',
                  data: data,
                  headers: {
                    Authorization: 'Bearer ' + this.header,
                  },
                })
                if (res.status === 200) {
                  this.caution = false
                  this.loading = false
                  this.valid = false
                  this.appear = true
                  this.semester = ''
                  this.year = ''
                  this.password = ''
                }
              } catch (e) {
                if (e.response && e.response.status === 401) {
                  this.$router.push({ name: 'login-page' })
                } else if (e.response && e.response.status === 400) {
                  this.loading = false
                  Swal.fire({
                    title: 'Error!',
                    text: e.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                  })
                  this.title = ''
                  this.code = ''
                  this.selected = ''
                  this.valid = false
                } else {
                  console.log(e)
                }
              }
            }
          },
        },
      )
    },
    async create_academic_year () {
      this.msg = 'New academic year created'
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      const data = {
        title: this.title,
      }
      try {
        const res = await this.axios({
          url: 'manipulate_academic_year',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.academics.push(res.data)
          this.appear = true
          this.title = ''
          this.on = false
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (e.response && e.response.status === 400) {
          alert(e.response.data.messages)
          this.title = ''
          this.code = ''
          this.selected = ''
          this.valid = false
        } else {
          console.log(e)
        }
      }
    },
    async fetchIntakes () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      try {
        const res = await this.axios({
          url: 'get_selected_intake',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.intakes = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async fetchAcademic () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      try {
        const res = await this.axios({
          url: 'get_academic_year',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.academics = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async fetchSchoolInfo () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_school_info',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.schoolInfo = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    // async fetchIntakes () {
    //   if (localStorage.getItem('logged_user')) {
    //     this.logger = JSON.parse(localStorage.getItem('logged_user'))
    //   }
    //   this.header = this.logger.accessToken
    //   try {
    //     const res = await this.axios({
    //       url: 'get_intakes/0',
    //       method: 'get',
    //       headers: {
    //         Authorization: 'Bearer ' + this.header,
    //       },
    //     })
    //     if (res.status === 200) {
    //       this.intakes = res.data
    //       console.log(res.data)
    //     }
    //   } catch (error) {
    //     if (error.response && error.response.status === 401) {
    //       this.$router.push({ name: 'login-page' })
    //     } else {
    //       console.log(error)
    //     }
    //   }
    // },
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload)
      this.fileRecordsForUpload = []
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        if (confirm('Are you sure you want to delete?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
  },
}
</script>

<style>
  .img-card {
    border: 3px solid #adb5bd;
    padding: 3px;
    width: 100px;
    height: 100px;
    margin: 10px 150px 0 150px;
    border-radius: 10px 10px 0 0;
  }

  .card-prop {
    background-color: white;
    border-top: #0e4ac4 solid 4px !important;
    border-radius: 5px 5px 5px 5px;
    margin: 10px 0 0 10px;
    padding: 0 20px 5px 0;
  }

  .line {
    margin: 0 10px 0 20px;
    border-radius: 0 0 0 5px;
    width: 270px;
    font-size: 18px;
    border-bottom: 1px #cccccc dotted;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .tabs {
    float: right !important;
    width: calc(100% - 400px) !important;
  }
  .holder {
    width:100%;
  }
  @media only screen and (max-width: 600px) {
    .tabs {
      float: left;
      width: 100%;
    }
    .card-prop {
      float: left;
      margin: 0;
      width: 100%;
    }
  }
</style>
